import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","clipped-left":"","clipped-right":"","color":"primary","dark":""}},[_c(VToolbarTitle,{staticClass:"align-center d-flex"},[_c('span',{staticClass:"logo-text",class:("" + (_vm.showLogo ? '' : 'hidelogo'))},[_c('img',{staticStyle:{"margin-top":"10px","height":"30px","width":"auto"},attrs:{"src":require("../../../assets/images/logo_per.png")}})]),_c('span',{staticClass:"ml-2 d-none d-sm-block",staticStyle:{"font-weight":"100","font-size":"14pt"}},[_vm._v("PERSCRIPTUM")]),_c('small',{staticClass:"ml-2 d-none d-sm-block"},[_vm._v("v3.0509")])]),_c('div',{on:{"click":_vm.showhideLogo}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.$vuetify.breakpoint.mdAndDown ? _vm.setSidebarDrawer(!_vm.Sidebar_drawer) : _vm.$emit('input', !_vm.value)}}})],1),_c(VSpacer),_c('base-documentation',{ref:"api_documentation",attrs:{"name":'Perscriptum',"src":_vm.getDocumentationURL},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c(VBtn,{attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.$refs.api_documentation.$refs.modal_documentation.open()}}},[_c(VIcon,{on:{"click":function($event){return _vm.$refs.api_documentation.$refs.modal_documentation.open()}}},[_vm._v(" mdi-help-circle-outline ")])],1)]},proxy:true}])}),_c(VBtn,{staticClass:"mr-2",attrs:{"dark":"","icon":""},on:{"click":_vm.setRightSidebar}},[(_vm.$store.getters.getNotifications.length > 0)?_c(VBadge,{attrs:{"color":"red","dot":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-bell")])],1):_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-bell")])],1),_c('small',{staticClass:"mr-2 d-none d-sm-block"},[_vm._v(_vm._s(_vm.$store.getters.profileName))]),_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-1",attrs:{"icon":""}},on),[_c(VAvatar,{attrs:{"right":"","color":"primary","size":"30"}},[(!_vm.$store.getters.profileImage)?_c(VIcon,{attrs:{"size":"30","color":"white"}},[_vm._v("mdi-account-circle")]):_c(VImg,{attrs:{"size":"30","src":_vm.$store.getters.profileImage}})],1)],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.$router.push('/profile')}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":"sm"},domProps:{"textContent":_vm._s(_vm.$icons['profile'])}})],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$store.getters.translate("profile")))])],1),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":"sm"},domProps:{"textContent":_vm._s('mdi-logout')}})],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$store.getters.translate("logout")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }